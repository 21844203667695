<template>
  <div>
    <section class="py-5 gifting">
      <!-- <a href="/" class="btn btn-danger rounded-pill btn-close-gift px-5"
        >Close</a
      > -->
      <div class="container">
        <!-- <div class="row mb-5">
          <div class="col-lg-12">
            <h5 class="text-center">Select a data plan</h5>
          </div>
        </div> -->

        <div class="row justify-content-center">
          <div class="col-lg-8 col-lg-offset-2">
            <form @submit.prevent="buyLoad()">
              <div class="row">
                <div class="col-lg-6 mb-3">
                  <div class="card shadow">
                    <div class="card-header">Your Order Details</div>
                    <div class="card-body py-3">
                      <div class="row g-2 mb-m20">
                        <div class="col-6">
                          <div class="selected-data-plan">
                            <small>Selected data plan</small>
                          </div>
                        </div>
                        <div class="col-6">
                          <div class="float-end">
                            <router-link to="topup"
                              ><small>Change data plan</small></router-link
                            >
                          </div>
                        </div>
                      </div>
                      <SkuItem
                        :skus="getSKU"
                        :background="getSKU.background"
                      ></SkuItem>
                      <h6 class="card-title">Recipient</h6>
                      <div class="mb-3">
                        <label class="form-label"
                          >Recipient's S2S Account number</label
                        >
                        <input
                          v-mask="'63877 #### ###'"
                          masked="false"
                          type="tel"
                          name="accountNumber"
                          v-model="form.accountNumber"
                          class="form-control form-control"
                          placeholder="63877 XXXX XXX"
                        />
                      </div>
                      <!-- <div class="mb-3">
                        <label class="form-label"
                          >Recipient's Mobile number</label
                        >
                        <input
                          v-mask="'+63 ### #### ###'"
                          masked="true"
                          type="tel"
                          required
                          v-model="form.mobileNumber"
                          class="form-control"
                          placeholder="+63 XXX XXXX XXX"
                        />
                      </div> -->

                      <!-- <h6 class="card-title mt-5">Sender</h6>
                      <div class="mb-3">
                        <label class="form-label">Sender's fullname</label>
                        <input
                          type="text"
                          v-model="form.sender"
                          required
                          class="form-control"
                          placeholder="Juan Dela Cruz"
                        />
                      </div>

                      <div class="mb-3">
                        <label class="form-label"
                          >Send receipt to <small>(Optional)</small></label
                        >
                        <input
                          type="email"
                          class="form-control"
                          v-model="form.emailAddress"
                          placeholder="juandelacruz@email.com"
                        />
                      </div> -->
                    </div>
                  </div>
                </div>
                <div class="col-lg-6 mb-3">
                  <div class="card shadow">
                    <div class="card-header">Checkout Summary</div>
                    <div class="card-body py-3">
                      <div class="breakdown-border">
                        <div class="row">
                          <div class="col-sm-7">Amount</div>
                          <div class="col-sm-5">
                            <div class="float-end">
                              {{ getSKU.items[0].price }}
                            </div>
                          </div>
                        </div>
                        <div class="row">
                          <div class="col-sm-7">Convenience Fee</div>
                          <div class="col-sm-5">
                            <div class="float-end">0.00</div>
                          </div>
                        </div>
                        <hr />
                        <div class="row">
                          <div class="col-sm-7">Total</div>
                          <div class="col-sm-5">
                            <div class="float-end">
                              {{ getSKU.items[0].price }}
                            </div>
                          </div>
                        </div>
                      </div>

                      <!-- <h6 class="card-title mt-5">Payment Methods</h6>
                      <div class="mb-3">
                        <label class="form-label">Select Payment Method</label>
                        <select class="form-control">
                          <option value="">GCash</option>
                          <option value="">Paymaya</option>
                        </select>
                      </div> -->

                      <!-- <div class=""> -->
                      <div class="col-lg-12 text-center mt-5">
                        <button
                          type="sumbit"
                          class="btn btn-dblue px-5"
                          :disabled="loadingBtn"
                        >
                          <div
                            v-if="loadingBtn"
                            class="spinner-border spinner-border-sm"
                            role="status"
                          >
                            <span class="visually-hidden">Loading...</span>
                          </div>
                          Pay now
                        </button>
                      </div>
                      <div
                        class="col-lg-12 text-center mt-2"
                        :disabled="loadingBtn"
                      >
                        <router-link class="btn btn-link px-5" to="/topup"
                          >Cancel</router-link
                        >
                      </div>
                      <!-- </div> -->
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
    </section>
    <button @click="topFunction" id="myBtn" title="Go to top">Top</button>
    <external-payment ref="externalPayment"></external-payment>
  </div>
</template>

<script>
import SkuItem from "../components/SkuItem.vue";
import ExternalPayment from "./modals/ExternalPayment.vue";

export default {
  name: "CheckoutSummary",
  components: {
    SkuItem,
    ExternalPayment,
  },
  data() {
    return {
      loadingBtn: false,
      skus: [],
      form: {
        accountNumber: "",
        mobileNumber: "",
        skuId: "",
        appName: "surf2sawa.com",
        sender: "",
        emailAddress: "",
        successUrl: "",
        failedUrl: "",
      },

      timer: null,
      // appName products mobilenumber, total, callback plgyan ng value
    };
  },
  methods: {
    config() {
      const d = new Date();
      const header = {
        headers: {
          "Content-Type": "application/json",
          "wsc-timestamp": d.getTime(),
          Authorization:
            "4eVJ60spdv60c56tKMGa+eYIE19fGs9rFhuRqzIDGtRJvaXJo1j61lPuUgQPg1bJtj5INmrwng==",
        },
      };
      return header;
    },

    async buyLoad() {
      this.loadingBtn = true;
      const { origin } = window.location;

      let source = window.localStorage.getItem("source");
      //   this.loadingBtn = true;
      //   let data = { ...this.form };
      //   data.skuId = this.$store.getters.GET_SKU.items[0].skuId;
      //   const AN = await this.trimMe(this.form.accountNumber);
      //   const MN = await this.trimMe(this.form.mobileNumber);
      //   data.accountNumber = AN;
      //   data.mobileNumber = MN;
      let data = { ...this.form };
      const payload = {
        mobileNumber: await this.trimMe(data.accountNumber),
        accountNumber: await this.trimMe(data.accountNumber),
        skuId: this.$store.getters.GET_SKU.items[0].skuId,
        appName: "PREPAID_FIBER",
        successUrl: `${origin}/success`,
        failedUrl: `${origin}/failed`,
        source: source || "surf2sawa",
      };

      // console.log(payload);

      // this.$store.dispatch("ADD_LOAD", this.$props.modalData.items[0]);
      await this.$load
        .post("/buy-load", payload, this.config())
        .then((response) => {
          const res = response.data;
          if (!res.status) {
            if (res.data === undefined) {
              this.loadingBtn = false;
              this.$swal({
                title: "Confirmation",
                icon: "warning",
                text: res.message,
                allowOutsideClick: false,
              }).then(() => {
                // this.logout();
                // this.$emit("response", "close-dialog");
                this.$emit("response", {
                  code: "close-dialog",
                  data: "",
                });
              });
              return;
            }

            if ("hasPending" in res.data) {
              this.$swal({
                title: "Confirmation",
                icon: "info",
                text: res.message,
                showCancelButton: true,
                focusConfirm: false,
                confirmButtonText: "Yes",
                cancelButtonText: "No",
                allowOutsideClick: false,
              }).then((result) => {
                if (result.isConfirmed) {
                  payload.force = true;
                  this.resend(payload);
                  // console.log(payload);
                } else {
                  this.loadingBtn = false;
                }
              });
            }
            // } else {

            // }
          } else {
            this.$store
              .dispatch("ADD_USER", data)
              .then(() => {
                this.loadingBtn = false;
                // window.location = res.data.redirectUrl;
                this.timer = setInterval(() => {
                  console.log(res.data);
                  // console.log("asdasdsad");
                  this.waitingPayment(res.data.paymentReferenceNumber);
                }, 5000);
                this.$refs.externalPayment.open(res.data);
              })
              .catch((err) => {
                console.log(err);
              });
            // this.$emit("response", {
            //   code: "show-payment-modal",
            //   data: res.data,
            // });
            // this.closeDialog();
            // window.location = res.data.redirectUrl;
          }
        })
        .catch((e) => {
          this.loadingBtn = false;
          console.log(e);
        });
    },

    async resend(payload) {
      await this.$load
        .post("/buy-load", payload, this.config())
        .then((response) => {
          const res = response.data;
          // console.log(response);
          this.loadingBtn = false;
          if (res.status) {
            this.$store
              .dispatch("ADD_USER", payload)
              .then(() => {
                // window.location = res.data.redirectUrl;
                this.timer = setInterval(() => {
                  console.log(res.data);
                  // console.log("asdasdsad");
                  this.waitingPayment(res.data.paymentReferenceNumber);
                }, 5000);
                this.$refs.externalPayment.open(res.data);
              })
              .catch((err) => {
                console.log(err);
              });
          } else {
            this.$swal({
              title: "Error",
              icon: "error",
              text: res.message,
              allowOutsideClick: false,
            });
          }
        })
        .catch((e) => {
          this.loadingBtn = false;
          console.log(e);
        });
    },
    // async payNow() {
    //   this.loadingBtn = true;
    //   let data = { ...this.form };
    //   data.skuId = this.$store.getters.GET_SKU.items[0].skuId;
    //   const AN = await this.trimMe(this.form.accountNumber);
    //   const MN = await this.trimMe(this.form.mobileNumber);
    //   data.accountNumber = AN;
    //   data.mobileNumber = MN;

    //   await this.$api2
    //     .post("/send-gift", data)
    //     .then((response) => {
    //       const res = response.data;
    //       if (!res.status) {
    //         if ("hasPending" in res.data) {
    //           this.$swal({
    //             title: "Confirmation",
    //             icon: "info",
    //             text: res.message,
    //             showCancelButton: true,
    //             focusConfirm: false,
    //             confirmButtonText: "Yes",
    //             cancelButtonText: "No",
    //             allowOutsideClick: false,
    //           }).then((result) => {
    //             if (result.isConfirmed) {
    //               data.force = true;
    //               this.resend(data);
    //               console.log(data);
    //             } else {
    //               this.loadingBtn = false;
    //             }
    //           });
    //         }
    //       } else {
    //         this.$store
    //           .dispatch("ADD_USER", data)
    //           .then(() => {
    //             this.loadingBtn = false;
    //             window.location = res.data.redirectUrl;
    //           })
    //           .catch((err) => {
    //             console.log(err);
    //           });
    //         console.log(data);
    //       }
    //     })
    //     .catch((e) => {
    //       this.loadingBtn = false;
    //       console.log(e);
    //     });
    // },

    // async resend(data) {
    //   await this.$api2
    //     .post("/send-gift", data)
    //     .then((result) => {
    //       const res = result.data;
    //       this.$store
    //         .dispatch("ADD_USER", data)
    //         .then(() => {
    //           this.loadingBtn = false;
    //           window.location = res.data.redirectUrl;
    //         })
    //         .catch((err) => {
    //           console.log(err);
    //         });

    //       this.loadingBtn = false;
    //     })
    //     .catch((err) => {
    //       console.log(err);
    //     });
    // },

    topFunction() {
      document.body.scrollTop = 0;
      document.documentElement.scrollTop = 0;
    },

    async waitingPayment(ref) {
      await this.$load
        .post("/waiting-payment", { referenceNumber: ref })
        .then((response) => {
          const res = response.data;
          console.log(res);
          if (!res.data.waitingPayment) {
            clearInterval(this.timer);
            this.$refs.externalPayment.cancel();
            window.location = res.data.redirectUrl;
            // });
          }
        })
        .catch((e) => {
          console.log(e);
        });
    },

    async trimMe(num) {
      return num.replace(/\s+/g, "").replace("+", "");
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },

  created() {
    // this.getSKUs();
    const isEmpty = Object.keys(this.$store.getters.GET_USER).length === 0;
    if (!isEmpty) {
      const user = this.$store.getters.GET_USER;
      this.form.sender = user.sender;
      this.form.emailAddress = user.emailAddress;
      this.form.accountNumber = user.accountNumber;
      this.form.mobileNumber = user.mobileNumber;
    }
    // console.log(this.$store.getters.GET_USER)
  },

  computed: {
    getSKU() {
      return this.$store.getters.GET_SKU;
    },

    // form: {
    //   get: function () {
    //     console.log(this.value);
    //     return this.value;
    //   },
    //   set: function (val) {
    //     this.$emit("input", val);
    //   },
    // },
  },

  watch: {
    form() {
      console.log(this.form);
    },
  },
};
</script>

<style scoped>
.mb-m20 {
  margin-bottom: -20px;
}

.card-title {
  color: #ff7200;
}

.form-label {
  font-size: 0.9rem;
}

.selected-data-plan {
  color: #69707a;
}

.card {
  color: #69707a;
  font-size: 0.9rem;
}

.breakdown-border {
  padding: 10px 10px;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  border-left: 4px solid #e7196a;
}
</style>
